.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 2rem;
}

.field-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
}

.input-wrapper {
    display: flex;
    flex-direction: rows;
    gap: 2rem;
    width: 100%;
}

form {
    width: 100%;
    max-width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

}

.button-wrapper {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: stretch;
}

.slider-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12rem;
    width: 100%;
    max-width: 70rem;
}

.pinned-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: fit-content;
    background-color: red;
}

.scroll-wrapper {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: calc(100% - 4rem);
    padding: 1rem;

    border-radius: 1rem;
    border: 1px solid var(--shade-2);
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.1);
}

.pinned-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: 10rem;
    background-color: pink;
    margin: 0 1rem;
}

.item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: fit-content;
    margin: 0 1rem;
    border-radius: 1rem;
    overflow: hidden;  
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
}

.item-wrapper:hover {
    cursor: pointer;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.selected {
    box-shadow: 0 0 1rem var(--palette-blue-1);
}

.selected:hover {
    box-shadow: 0 0 1rem var(--palette-blue-2);
}

.item-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


