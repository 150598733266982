.content-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: fit-content;
  width: 100%;  
  height: 80vh;
  gap: 2rem;
}

.form-wrapper {
  width: 100%;
  transition: opacity .5s ease-in-out;
}

.fade-in {
  opacity: 1;
  pointer-events: all;
}

.fade-out {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.scroll-container {
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  position: relative;
  max-width: 40rem;
  width: calc(100% - 2rem);
  min-height: 1rem;
  background-color: var(--shade-2);
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer; /* Make the container clickable */
}

.scroll-bar {
  position: absolute;
  top: .25rem;
  width: 45%;
  height: 50%;
  margin: 0 .25rem;
  background: linear-gradient(90deg, var(--palette-blue-1), var(--palette-blue-3));
  border-radius: 25px;
  transition: all 0.5s ease;
  z-index: 1;
}

.scroll-bar.on {
  left: 0; /* Move to the left when "on" */
}

.scroll-bar.off {
  left: calc(55% - .5rem); /* Move to the right when "off" */
}

@media (prefers-color-scheme: dark) {
  .scroll-container {
    background-color: var(--palette-blue-1);
  }

  .scroll-bar {
    
    background: none;
    background-color: var(--shade-3);
  }
}

@media only screen and (max-width: 768px) {
  /* Add your mobile-specific CSS here */
  .content-wrapper {
    height: fit-content, 60rem;
  }
}
