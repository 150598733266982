.sign-up {
    color: white;
}

.auth-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--shade-1);
    color: var(--shade-3);
    font-size: 1rem;
    font-weight: 700;
    padding: .5rem 1rem;
    border: 1px solid var(--palette-blue-3);
    border-radius: 5px;
    cursor: pointer;
}

.switchTypeDescription, .switchTypeButton, .Button-Description {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: var(--shade-4);
    margin: 0;
}

.dividor {
    margin:0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: hsla(220, 20%, 80%, 0.4);
    border-bottom-width: thin;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    text-align: center;
    border: 0;
    border-top-style: solid;
    border-left-style: solid;
    align-items: center;
    justify-content: center;
}

.dividor-text {
    display: inline-block;
    padding-left: calc(8px* 1.2);
    padding-right: calc(8px* 1.2);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.dividor::before, .dividor::after{
    width: 100%;
    border-top: thin solid hsla(220, 20%, 80%, 0.4);
    border-top-style: inherit;
    content: "";
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.form-logo {
    margin-bottom: -2px;
    margin-right: 4px;
}

.text-wrapper {
    width: 100%;
    text-align: center;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.text-wrapper a{
    text-decoration: underline;

}

.text-wrapper a:hover{
    cursor: pointer;
}

.invisible {
    display: none;
    opacity: 0;
}

.visible {
    opacity: 1;
}

.content-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: fit-content;
    height: 80vh;
    gap: 2rem;
}

.dash-wrapper {
    display: flex;
    justify-content: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
