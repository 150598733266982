.drop-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
}


.click-drop-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
}

.click-drop-wrapper:hover {
    cursor: pointer;
}

.pdf-wrapper {
    height: inherit;
    width: inherit;
    overflow-y: hidden;
}

.pdf-page-wrapper {
    align-items: center;
    height: 100%;
}

.pdf-page-wrapper canvas {
    width: 100% !important;
    height: auto !important;
    top: 0;
}

.container {
    width: 100%;
    border-radius: 1rem;
    border: 1px solid var(--shade-2);
    height: 100%;
}
