.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: max-;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
}

.input-wrapper {
    display: flex;
    flex-direction: rows;
    gap: 2rem;
    width: 100%;
}

.unfilled {
    border: 1px solid red;
}

form {
    width: 100%;
    max-width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
}

.drop-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    max-width: 32rem;
    padding: 1rem;
}
