.banner-wrapper {
    min-height: 20vh;
    width: 100%;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: var(--shade-1);
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
    gap: 1rem;
    width: 100%;
}

.content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: calc(100% - 4rem);
    max-width: 80rem;
    border-top: 1px solid var(--shade-2);
    flex-wrap:wrap;
    align-items: right;
    padding: 1rem 2rem;
}

.option-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap:wrap;
}

.last-option-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    text-align: end;
}

.column-wrapper {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 12.5rem;
}

.last-option-wrapper .column-wrapper {
    align-items: right;
    width: fit-content;
}

.option-wrapper .column-wrapper {
    align-items: start;
}

a {
    text-decoration: none;
    color: inherit;
}

a:visited {
    text-decoration: none;
}


@media (max-width: 485px) {
    .last-option-wrapper {
        text-align: start;
    }
}