.content-wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    min-height: fit-content;
    width: calc(100% - 4rem);
    height: 80vh;
    gap: 2rem;
    padding: 0 2rem;
}

.identicon-wrapper {
    width: 7rem;
    height: 7rem;
}

.dash-wrapper {
    max-width: 50rem;
    min-width: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.background-shape {
    position: absolute;
    width: 22rem;
    height: 22rem;
    z-index: -1;
    opacity: .3;
    transform: translate(-4vw, -30%);
}



.background-shape svg path {
    -webkit-filter: drop-shadow(.1rem .1rem .1rem var(--shade-2));
    filter: drop-shadow(.1rem .1rem .1rem var(--shade-2));
    /* Similar syntax to box-shadow */
}

.field-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 2px solid var(--shade-3);
    border-radius: 1rem;
    flex-wrap: wrap;

    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.datafield-container {
    display: flex;
    flex-direction: column;
    padding: .5rem;
    width: fit-content;

    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.datafield-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: space-between;
    border: 1px solid var(--shade-2);
    border-radius: 1rem;
    flex-wrap: wrap;

    padding: .5rem;
    background-color: var(--shade-1);
}

.field-wrapper h2 {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.2rem;
    color: var(--shade-5);
    letter-spacing: .2rem;
}

.field-wrapper section {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    padding: .5rem 0;

}

.request-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: .2rem;
}

@media (max-width: 485px) {
    .background-shape {
        transform: translate(-13rem, -30%);
        width: 25rem;
        height: 25rem;
    }
}