.banner-wrapper {
    min-height: 3.5rem;
    display: flex;
    position: fixed;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: var(--shade-1);
    border-bottom: 1px solid var(--shade-2);
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
    gap: 1rem;
    z-index: 100;
    top: 0;
}

.content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: calc(100% - 4rem);
    max-width: 80rem;
    padding: 0 2rem;
}

.content-wrapper img {
    height: 2rem;
    margin: auto;
}

.content-wrapper a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.option-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    /* padding: 0 1rem; */
}

.option-wrapper-right {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border-left: 1px solid var(--shade-2);
}

.select-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.beta-banner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--shade-1);
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
}

