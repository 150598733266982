.main-wrapper {
    min-height: fit-content;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--shade-1);
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.25rem;
    text-align: center;
    gap: 1rem;
    padding: 2rem 2rem;
}

.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50vh;
    min-height: fit-content;
    width: calc(100% - 4rem);
    padding: 2rem;
    gap: 2rem;
}

.inner-content-wrapper {
    width: 65rem;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8rem;
}

.facts-block-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.marketing-sub-wrapper svg {
    display: block;
    position: absolute;
    z-index: -1;
    margin-right: 35rem;
}

.facts-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: fit-content;
    max-width: 50rem;
    align-items: center;
    justify-content: center;
}

.facts-wrapper h3 {
    font-size: 1.1rem;
    font-weight: 300;
    color: var(--shade-5);
}

.marketing-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
}

.marketing-wrapper .emoji {
    position: absolute;
    font-size: 15rem;
    rotate: 60deg;
    z-index: -1;
    margin-right: 10rem;
    margin-top: -8rem;
    opacity: .2;
}

.marketing-sub-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
}


.main-prompt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.main-prompt-wrapper p {
    position: absolute;
}

.main-text-wrapper {
    display: flex;
    flex-direction: column;
}

.sign-up-button {
    display: flex;
    justify-content: center;
    margin: 1rem;
    gap: 1rem;
}

.company-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem 2rem;
    width: calc(100% - 4rem);
}

.company-background-light {
    background-color: var(--shade-2);
}

.company-background-dark {
    background-color: var(--shade-2);
}

.company-row {
    display: flex;
    flex-direction: row;
    justify-content: center;   
    gap: 6rem; 
    height: fit-content;
    width: 100%;
    flex-wrap: wrap;
}

.company-logo {
    display: block;
    height: 2rem;
    fill: var(--shade-3) !important;
}

grad-animation {
    background: linear-gradient(70deg, 
        var(--palette-blue-2) 0%,   /* Blue starts on the left */
        var(--palette-red-1) 90%); /* Red is on the right */
    font-weight: 800;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%; /* Make the background large enough for animation */
    animation: flow 3s infinite; /* Infinite loop with linear timing */
}

@keyframes flow {
    0% {
        background-position: 25% 75%; /* Start from the left */
      }
      50% {
        background-position: 100% 25%; /* Move to the right */
      }
      100% {
        background-position: 25% 75%; /* Move back to the left */
      }
}

@media (max-width: 485px) {
    .marketing-wrapper .emoji {
        margin-top: -6rem;
        margin-right: 10rem;
        position: absolute;
        font-size: 15rem;
        
    }

    .main-text-wrapper {
        gap: 1rem;
    }

    .main-text-wrapper h4 {
        text-align: center;
    }

    .company-wrapper {
        gap: 2rem;
    }

    .company-row {
        gap: 2rem;
    }

    .company-logo {
        display: block;
        width: 35vw;
        fill: var(--shade-3) !important;
    }
}
