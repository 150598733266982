@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&family=Noto+Sans:ital,wdth,wght@0,62.5..100,100..900;1,62.5..100,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --palette-red-1: #FE7874;
    --palette-red-2: #E34640;
    --palette-red-3: #9B3430;
    --palette-blue-1: #6FB9B1;
    --palette-blue-2: #499C9B;
    --palette-blue-3: #2A5B67;

    --shade-1: #FFFFFF;
    --shade-2: #EAEBEA;
    --shade-3: #8F908F;
    --shade-4: #252524;
    
    font-size: 16px;
}

body {
    background-color: var(--shade-1);
    font-weight: 400;
    font-family: 'Noto Serif', sans-serif;
}

.content-container {
    border-radius: 1rem;
    border: 1px solid var(--shade-2);
}

h1 {
    font-family: 'Noto Serif', sans-serif;
    font-size: 3.5rem;
    font-weight: 400;
    color: var(--shade-4);
    margin: 0;
    width: fit-content;
}

h2 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: var(--shade-4);
    margin: 0;
    width: fit-content;
}

h3 {
    font-family: 'Noto Sans', sans-serif;
    font-size: .8rem;
    font-weight: 400;
    color: var(--shade-3);
    margin: 0;
    width: fit-content;
}

h4 {
    text-align: right;
    font-family: 'Noto Sans', sans-serif;
    font-size: .6rem;
    font-weight: 400;
    color: var(--shade-3);
    margin: 0;
    width: fit-content;
}

h5 {
    font-family: 'Noto Serif', sans-serif;
    font-size: 1.5rem;
    font-weight: 200;
    color: var(--shade-3);
    letter-spacing: .2rem;
    margin: 0;
    width: fit-content;
}

h6 {
    font-family: 'Noto Sans', sans-serif;
    font-size: .8rem;
    font-weight: 400;
    color: var(--shade-3);
    margin: 0;
    width: fit-content;
}

red {
    color: var(--palette-red-1);
}

blue {
    color: var(--palette-blue-2);
}

ul {
    margin: 0;
    margin-left: -1rem;
}

li {
    font-family: 'Noto Sans', sans-serif;
    font-size: .9rem;
    font-weight: 500;
    color: var(--shade-3);
    margin: .5rem 0 0 -.5rem;
}

li {
    list-style-type: "✓";

}

li span {
    position: relative;
    right: -.5rem;
}

li::marker {
    font-weight: 800;
    color: var(--shade-3)
}

hr {
    border-color: var(--palette-blue-3);
    opacity: 0.5;
}

label {
    font-family: 'Noto Sans', sans-serif;
    font-size: .8rem;
    font-weight: 400;
    color: var(--shade-3);
    margin: 0;
    width: 100%;
}

label[type='checkbox'] {
    display: flex;
    align-items: center;
    gap: .5rem;
}

input {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--shade-4);
    background-color: inherit;
    width: 100%;
    margin: .25rem 0;
    border: none;
    padding: .25rem 0;
    border-bottom: 1px solid var(--shade-2);
}

input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    accent-color: var(--shade-1);
}

input:focus {
    outline: none;
    border-bottom: 1px solid var(--shade-3);
}

button {
    background-color: var(--shade-1);
    color: var(--shade-3);
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem 1rem;
    border: 1px solid var(--shade-2);
    border-radius: 0.3rem;
    cursor: pointer;
}

.button {
    width: 100%;
    box-shadow: 0 0 .4rem 0rem var(--shade-2);
    color: var(--palette-blue-2) !important;
    background: var(--shade-1) !important;
    border: 1px solid var(--shade-2) !important;
}

textarea {
    font-family: 'Noto Sans', sans-serif;
    font-size: .8rem;
    font-weight: 400;
    color: var(--shade-4);
    background-color: inherit;
    width: 100%;
    padding: 0.25rem 0;
    margin: .25rem 0;
    border: 1px solid var(--shade-2);
    border-top: 1px dashed var(--shade-2);
    border-right: none;
    border-left: none;
    resize: none;
}

textarea:focus {
    outline: none;
    border: 1px solid var(--shade-3);
    border-top: 1px dashed var(--shade-3);
    border-right: none;
    border-left: none;
}

grad {
    background: -webkit-linear-gradient(70deg, var(--palette-blue-2), var(--palette-red-1) 90%);
    font-weight: 800;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

bold {
    font-weight: 600;
}

@media (prefers-color-scheme: dark) {
    :root {
        --palette-red-3: #FE7874;
        --palette-red_2: #E34640;
        --palette-red_1: #9B3430;
        --palette-blue-3: #6FB9B1;
        --palette-blue-2: #499C9B;
        --palette-blue-1: #2A5B67;

        --shade-1: #161616;
        --shade-2: #252524;
        --shade-3: #8F908F;
        --shade-4: #EAEBEA;
        --shade-5: #FFFFFF;
        
    }

    body {
        background-color: var(--shade-1);
        font-weight: 400;
        font-family: 'Noto Serif', sans-serif;
    }
}

@media (max-width: 485px) {
    h1 {
        font-size: 2.6rem;
        font-weight: 500;
    }

    h5 {
        font-size: 1rem;
    }

    button h2 {
        font-weight: 300;
    }
    
}