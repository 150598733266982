.main-wrapper {
    min-height: 64rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--shade-1);
    font-weight: 700;
    text-align: center;
    gap: 2rem;
    padding: 2rem 2rem;
}

.main-prompt-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 717px;
    gap: 15px;
}

.main-header {
    font-size: 3rem;
}

.pricing-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 1.6rem;
    padding: 2rem;
    z-index: 1;
    flex-wrap: wrap;
}

.pricing-box {
    background-color: var(--shade-1);
    border: 1px solid #ddd;
    border-radius: .25rem;
    width: 14.5rem;
    padding: 2rem;
    text-align: left;
    box-shadow: 0 0 .5rem 0rem var(--shade-3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5rem;
    transition: box-shadow .5s;
    position: relative;
    height: calc(100% - 4rem);
}

.pricing-box-premium::before,
.pricing-box-premium::after {
	content: "";
	position: absolute;
	top: .5rem;
	left: .5rem;
	background: linear-gradient(
		45deg,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000
	);
	background-size: 300%;
	width: calc(100% - 1rem);
	height: calc(100% - 1rem);
	z-index: -1;
	animation: animate 20s linear infinite;
    opacity: 0.4;
}

.pricing-box-premium::after {
	filter: blur(20px);
}

.pricing-box-premium:hover {
    box-shadow: 0 0 .5rem 0rem rgba(255, 215, 0, .5);
}

.pricing-box-basic:hover {
    box-shadow: 0 0 .5rem 0rem var(--palette-blue-2);
}

.pricing-box-trial:hover {
    box-shadow: 0 0 .5rem 0rem var(--palette-red-3);
}

@keyframes animate {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 300% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.pricing-box h3 {
    margin-bottom: 1rem;
}

.price-heading {
    font-size: 2.5rem;
    font-weight: 500;;
}

.price {
    color: var(--palette-blue-3);
}

.price-button {
    margin-top: 1rem;
    background-color: var(--shade-1);
    color: var(--shade-3);
    font-size: 1rem;
    font-weight: 700;
    padding: .5rem 1rem;
    border: 1px solid var(--palette-blue-3);
    border-radius: 5px;
    cursor: pointer;
}

.perk-list {
    color: var(--shade-2);
    font-size: 1rem;
    font-weight: 400;
}

.pricing-box h4 {
    color: var(--shade-3);
    margin-top: 1rem;
}

hr {
    margin-left: -1.5rem;
}

@media (prefers-color-scheme: dark) {
    .pricing-box {
        box-shadow: 0 0 .5rem 0rem var(--shade-2);
    }
}
