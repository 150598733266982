form {
    width: 100%;
    max-width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
}

.skill-box-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: calc(100% - 2rem);
    max-width: 32rem;
    flex-wrap: wrap;

    border: 1px solid var(--shade-2);
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0 .4rem 0rem rgba(0, 0, 0, 0.2);
    margin-bottom: .5rem;
}

.bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    box-shadow: inset 0 0 .3rem 0 var(--shade-2);
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    transition: box-shadow .5s ease;

    position: relative;
    padding: .5rem .6rem;
}

.disabled {
    opacity: 0.7;
    pointer-events: none;
}

.blurred {
    filter: blur(.15rem);
    pointer-events: none;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    box-shadow: inset 0 0 .3rem 0 var(--shade-3);
}

.active {
    box-shadow: inset 0 0 .3rem 0 var(--palette-blue-1);
    color: var(--shade-2);
}

.form-group {
    margin: 0 0 .5rem 0 !important;
    gap: 1rem;
}

.form-group h2 {
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    gap: 1rem;
}

.slider-group {
    margin: 0 1rem;
    max-width: calc(100% - 2rem);
}

.customize-wrapper h4 {
    font-size: .8rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    padding: 0 1rem;
}

.dropdown-wrapper {
    margin: .5rem 0;
}

.dropdown-group {
    box-shadow: 0 0 .4rem 0rem rgba(0, 0, 0, 0.2);
    border-radius: 1rem !important;
    border: 1px solid var(--shade-2);
    color: var(--shade-3) !important;
}

.dropdown-item {
    color: var(--shade-3) !important;
}
