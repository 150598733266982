.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: calc(100% - 4rem);
    align-items: center;
    padding: 0 2rem;
    gap: 5rem;
}

.section-wrapper {
    min-width: fit-content;
    max-width: 65rem;
    width: 100%;
    padding: 0 2rem;
}

.text-section {
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: fit-content;
    min-width: fit-content;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

.textbox-wrapper {
    display: flex;
    flex-direction: column;

    align-items: right;
    justify-content: right;
}

.details-wrapper {
    padding: 1rem 0;
    display: flex;
    align-items: right;
    justify-content: right;
    width: 60%;
    margin-left: auto;
}

.text-overlay {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

#title-container {
    justify-content: left;
    text-align: left;
    min-height: fit-content;
}

#mission-container {
    justify-content: right;
    text-align: right;
    min-height: fit-content;
}

.text-overlay h3 {
    text-align: center;
}


.values-section {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: center;
    padding: 1rem;
    background-color: var(--shade-2);
}

.team-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    background-color: var(--shade-1);
    margin: 2rem 0 10rem 0;
    justify-content: stretch;
    max-width: 75rem;
    width: 100%;
}

.team-section-title {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    width: inherit;
}

.team-section-image {
    display: flex;
    justify-content: center;
    max-width: 75rem; /* Set a maximum width for the container */
    width: 100%; /* Allow it to be responsive */
    overflow: hidden; /* Ensure no overflow */
}

.team-section-image img {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Allow the image to be responsive */
}

.team-members {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 5rem;
    flex-wrap: wrap;
}

.member {
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 17.5rem;
    gap: 1rem;
}

.member p {
    color: var(--shade-4);
    font-size: 0.9rem;
    word-wrap: break-word;
}

.member a {
    color: var(--shade-4);
    font-weight: 600;
}



@media (max-width: 485px) {
    .details-wrapper {
        width: 80%;
    }

    .text-overlay h1 {
        font-size: 2.4rem;
    }

    .text-overlay h2 {
        font-size: 1rem;
    }

    .text-overlay p {
        font-size: 0.75rem;
    }
}