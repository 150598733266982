.policy-container {
    margin: 1.25rem auto;
    max-width: 50rem;
    padding: 1.25rem;
    background-color: var(--shade-1);
}

.policy-text {
    color: var(--shade-2);
}

.policy-list {
    padding-bottom: 1rem;
}

.policy-list li {
    color: var(--shade-2);
    padding-left: 0.5rem;
}